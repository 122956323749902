.InsertMainContainer {
  animation: listing-fadein 1s ease 0s 1 normal forwards;
  background-color: rgba(182, 35, 35, 0);
  width: 90%;
  height: 70%;
  position: absolute;
  left: 5%;
  top: 30%;
  text-align: center;
  justify-content: center;
  display: flex;
}

.InsertForm {
  background-color: rgba(71, 109, 71, 0.329);
  border-radius: 10px;
  position: relative;
  width: 25%;
  height: 70%;
  /* padding-top: 15%; */
}

.InsertFormContent {
  /* background-color: red; */
  margin-top: 10%;
}

.InsertPreviewContent {
  /* background-color: red; */
  margin-top: 50%;
}

.slide-left {
  animation: leftSlide 1s ease 0s 1 normal forwards;
}

.slide-right {
  animation: rightSlide 1s ease 0s 1 normal forwards;
}

.InsertPreview {
  background-color: rgba(0, 132, 255, 0);
  border-radius: 10px;
  position: absolute;
  width: 25%;
  height: 70%;
  left: 52%;
  /* transform: translateX(60%); */
  animation: listing-fadein 1s ease 0s 1 normal forwards;
}

.modified-select {
  margin-top: 10px;
  width: 95%;
  padding: 10px;
  text-align: center;
  position:relative;
  left: 50%;
  transform:translate(-50%, 0%);
}

.small-select {
  margin-top: 20px;
  width: 50%;
}

.btn {
  position: relative;
  margin: 5px;
}


.input {
  width: 100%;
  max-width: 220px;
  height: 45px;
  padding: 12px;
  border-radius: 12px;
  border: 1.5px solid lightgrey;
  outline: none;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0px 0px 20px -18px;
  text-align:center;
  margin: 10px;
}

.input:hover {
  border: 2px solid lightgrey;
  box-shadow: 0px 0px 20px -17px;
}

.input:active {
  transform: scale(0.95);
}

.input:focus {
  border: 2px solid grey;
}

.input-long {
  max-width: 95%;
}



.titleContainer {
  background-color: rgba(255, 0, 0, 0);
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.title {
  font-size: 1.5vw;
  color: rgb(250, 247, 220);
  font-weight: 600;
  letter-spacing: -1px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 0.4px;
}

.title::before {
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: rgb(224, 203, 134);
  animation: pulse 1s linear infinite;
}

.titleNewColor {
  color: rgb(255, 115, 0);
}

.titleNewColor::before {
  background-color: rgb(255, 115, 0);
}

.card {
  width: 10vw;
  height: 30vh;
  background: white;
  padding: .4em;
  left: 50%;
  transform:translate(-50%,25%);
  border-radius: px;
}

.card-image {
  /* transform:translate(50%, 0%); */
  background-color: rgb(236, 236, 236);
  width: 100%;
  height: 60%;
  border-radius: 6px 6px 0 0;
}

.card-image img {
  width: 50%;
  transform:translate(70%, -20%);
}

.card-image:hover {
  transform: scale(0.98);
}

.category {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
  color: rgb(63, 121, 230);
  padding: 10px 7px 0;
}

.category span {
  margin: 1px;        
}

.category:hover {
  cursor: pointer;
}

.heading {
  font-weight: 600;
  color: rgb(88, 87, 87);
  padding: 7px;
}

.heading:hover {
  cursor: pointer;
}

.author {
  color: gray;
  font-weight: 400;
  font-size: 11px;
  padding-top: 20px;
}

.name {
  font-weight: 600;
}

.name:hover {
  cursor: pointer;
}

.prevLink {
  
}


/* PHONE CSS */
@media (max-width: 480px) {
  
  .InsertMainContainer {
    animation: listing-fadein 1s ease 0s 1 normal forwards;
    width: 90%;
    height: 80%;
    position: absolute;
    left: 5%;
    top: 10%;
    text-align: center;
    justify-content: center;
    display: flex;
  }

  .InsertForm {
    background-color: green;
    position: absolute;
    border-radius: 10px;
    width: 40%;
    height: 100%;
  }

  .InsertPreview {
    background-color: aqua;
    position: absolute;
    border-radius: 10px;
    width: 40%;
    height: 100%;
    left: 53%;
    /* transform: translateX(60%); */
    animation: listing-fadein 1s ease 0s 1 normal forwards;
  }

  .modified-select {
    width: 95%;
    padding: 10%;
    /* height: */
    font-size: 2vw;
    text-align: center;
  }

  .title {
    font-size: 3vw;
    color: rgb(225, 188, 65);
    font-weight: 600;
    letter-spacing: -1px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-flex;
    align-items: center;
  }
}






@keyframes leftSlide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-60%);
  }
}

@keyframes rightSlide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(60%);
  }
}

@keyframes form-fadein {
    0% {
      opacity: 0;
      transform: scale(0.6);
    }
  
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

@keyframes pulse {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.8);
    opacity: 0;
  }
}