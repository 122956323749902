  .MainContainer {
    width: 100vw;
    /* height: 50vh; */
    /* margin-top: 10px; */
    /* background-color: #FFEAD2; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 94vh;
  }


  @keyframes Welcome {
    0% {
      transform: scale(0.5);
    }
  
    100% {
      transform: scale(1);
    }
  }
  
  
  @keyframes hoverAnimation {
  
    0% {
      tranform: translateY(-10px);
    }
    100% {
      transform: translateY(-20px);
    }
  
  }


footer {
   position:absolute;
   top: 30%;
   font-size: 12px;
   animation: Welcome 2s ease 0s 1 normal forwards;
}


#Welcome_Load {
    margin-bottom: 90px;
    width: 50vw;
}

@media (max-width: 1024px){
  #Welcome_Load {
      width: 100vw;
  }

}


@media (max-width: 480px){
  #Welcome_Load {
      width: 95vw;
  }

}

.growAnimation {
  animation: Welcome 1s ease 0s 1 normal forwards;
}

.hoverAnimation {
  animation: hoverAnimation 1.5s infinite alternate;
}



.a-footer {
    box-shadow: inset 0 0 0 0 #54b3d6;
    color: #54b3d6;
    padding: 0 .25rem;
    margin: 0 -.25rem;
    transition: color .3s ease-in-out, box-shadow .3s ease-in-out;
  }
  .a-footer:hover {
    color: #fff;
    box-shadow: inset 200px 0 0 0 #54b3d6;;
  }
  
  /* Presentational styles */
  .a-footer {
    color: #00000062;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 800;
    line-height: 1.5;
    text-decoration: none;
  }



  .ellipse {
    position: absolute;
    margin-top: 35px;
    width: 500px;
    height: 50px;
    background-color: #6e6e6e44;
    border-radius: 75%;
    animation-name: ellipse-animation;
    animation-duration: 3.0s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @media (max-width: 500px) {
    .ellipse {
    position: absolute;
    margin-top: -25px;
    width: 250px;
    height: 30px;
    background-color: #6e6e6e44;
    border-radius: 75%;
    animation-name: ellipse-animation;
    animation-duration: 3.0s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  }
  
  @keyframes ellipse-animation {
    0% {
      transform: scaleX(0.7);
    }
    50% {
      transform: scaleX(0.5);
    }
    100% {
      transform: scaleX(0.7);
    }
  }

  .discordIframe {
    position: absolute;
    left: 75%;
    top: 5%;
    width: 20vw;
    height: 90vh;
  }
  
  .version {
    color: #262c2e97;
    position: relative;
    margin-top: 400px;
  }