.image-preview {
    max-height: 100vh;
    margin-top: 200px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.image-container {
    display: flex;
    justify-content: center;
}

.preview-image {
    /* width: 100px;  */
    object-fit: cover; /* Ensure the image covers the area without distortion */
}

.messages-container {
    max-height: 50vh;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
}

.message-item {
    margin-bottom: 10px;
}

.bn632-hover {
    left: 50%;
    /* transform:translate(-100%, 100%); */
    width: 160px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin: 10px;
    height: 55px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    transition: all .4s ease-in-out;
}

.bn632-hover:hover {
    background-position: 100% 0;
    transition: all .4s ease-in-out;
}

.bn632-hover:focus {
    outline: none;
}

.bn632-hover.bn21 {
    background-image: linear-gradient(
      to right,
      #fc6076,
      #ff9a44,
      #ef9d43,
      #e75516
    );
    box-shadow: 0 4px 15px 0 rgba(252, 104, 110, 0.75);
}

.parent-container {
    text-align: center;
}

.media-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.media-container {
    margin: 10px;
    text-align: center;
}

.preview-media {
    max-width: 50%;
    height: auto;
}

.preview-image {
    max-width: 100%;
    height: auto;
}

/* New class for the show/hide button */
.toggle-button {
    position: absolute;
    transform: translateY(5px);
    width: 70px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    margin-left: 30px;
    height: 30px;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    transition: all .4s ease-in-out;
    background-image: linear-gradient(
      to right,
      #fc6076,
      #ff9a44,
      #ef9d43,
      #e75516
    );
    box-shadow: 0 4px 15px 0 rgba(252, 104, 110, 0.75);
}

.toggle-button:hover {
    background-position: 100% 0;
    transition: all .4s ease-in-out;
}

.toggle-button:focus {
    outline: none;
}

.extractHeader {
    margin-top: 50px;
    margin-bottom: 20px;
}
