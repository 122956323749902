.Main {
    width: 100%;
    height: 50%;
    background-color: rgb(156, 147, 147);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }


  #Welcome_Load2 {
    position:absolute;
    top:10%;
    margin-bottom: 60px;
    animation: Welcome2 2s ease 0s 1 normal forwards;
}


@keyframes Welcome2 {
	0% {
		transform: scale(0.5);
	}

	100% {
		transform: scale(1);
	}
}

@media (max-width: 2000px){
    img {
        width: 50%
    }

}


.login-card {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #e8e8e8a8;
    box-shadow: 2px 2px 10px #ccc;
  }
  
  .card-header {
    text-align: center;
    margin-bottom: 20px;
}
  #succ{
    background-color: rgb(98, 253, 26);
    border-radius: 6px;
    border-color: black;
    animation: swing 1s ease 0s 1 normal forwards;
  }

  #err{
    background-color: rgb(250, 83, 83);
    border-radius: 6px;
    border-color: black;
    animation: swing 1s ease 0s 1 normal forwards;
  }
  .card-header .log {
    margin: 0;
    font-size: 24px;
    color: black;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  input[type="text"], input[type="date"] {
    width: 100%;
    padding: 12px 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    transition: 0.5s;
  }
  
  input[type="submit"] {
    width: 100%;
    background-color: #333;
    color: white;
    padding: 20px 20px;
    margin: 2px 0;
    border: none;
    border-radius: 2px;
    cursor: pointer;
  }
  
  input[type="submit"]:hover {
    background-color: #ccc;
    color: black;
  }
  
  #homebtn {
    position:absolute;
    top:90%;

  }

  #submitbtn{
    position:relative;
    margin-left: 30%;
  }

  @keyframes swing {
	0% {
		opacity: 0;
		transform: rotateX(-100deg);
		transform-origin: top;
	}

	100% {
		opacity: 1;
		transform: rotateX(0deg);
		transform-origin: top;
	}
}